const apiUrl = import.meta.env.VITE_API_URL;
const clientVersion = import.meta.env.VITE_FULL_VERSION;

const checkVersion = async () => {
  try {
    const response = await fetch(`${apiUrl}/api/version`);
    const { version: apiVersion } = await response.json();

    if (apiVersion && apiVersion !== clientVersion) {
      window.location.reload();
    }
  } catch (e) {
    console.log(e);
  }
};

export const initializeVersionCheck = () => {
  window.fullVersion = clientVersion;
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      checkVersion();
    }
  });
};
